<template>
    <v-container fluid>
        <v-dataTable
            ref="childComponentRef"
            listName="Tasks"
            :hide-add-new="true"
            :selected="selected"
            :isBusy="isBusy"
            :deletingPermission="deletingPermission"
            :viewStatusLabel="viewStatusLabel"
            :abilityToSearch="abilityToSearch"
            :pager="pager"
            :pageSizes="pageSizes"
            :viewStatusOptions="viewStatusOptions"
            :viewStatus="viewStatus"
            :searchAt="searchAt"
            :searchOnFields="searchOnFields"
            :searchText="searchText"
            @toggle-search="toggleSearchAt"
            @search-change="searchChange"
            @view-status="viewStatusClick"
            @page-load-data="loadData"
            @next-page="loadData"
            @previous-page="loadData"
            @input-page="loadData"
            @page-size-change="loadData">
            <v-data-table
                group-by="StatusDescription"
                :headers="headers"
                :items="tableData"
                :items-per-page="pager.size"
                :loading="isBusy"
                :sort-by.sync="sortOptions.sortBy"
                :sort-desc.sync="sortOptions.sortDesc"
                multi-sort
                hide-default-footer
                no-data-text="No data."
                @click:row="onSelect">
                <template v-slot:group.header="{ group, headers, toggle, isOpen }">
                    <td :colspan="headers.length">
                        <v-btn @click="toggle" x-small icon :ref="group">
                            <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                            <v-icon v-else>mdi-chevron-down</v-icon>
                        </v-btn>
                        <span class="mx-5 font-weight-bold">{{ group }}</span>
                    </td>
                </template>
                <template v-slot:item.Name="{ item }">
                    <div class="subtitle-2 cur-default">{{ item.Name }}</div>
                </template>
                <template v-slot:item.UpdateDate="{ item }">
                    <span class="cur-default">{{ $format.dateTimeCustom(item.UpdateDate) }}</span>
                </template>
                <template v-slot:item._id="{ item }">
                    <v-btn color="success" x-small @click.stop="showDetails(item)" v-if="item.StatusId !== 'C' && item.WorkflowMetaData.length > 0">Show</v-btn>
                    <v-chip color="warning" x-small v-if="item.StatusId !== 'C' && item.WorkflowMetaData.length === 0">No Details</v-chip>
                    <v-chip class="success" x-small v-if="item.StatusId === 'C'"></v-chip>
                </template>
                <template v-slot:item.StatusId="{ item }">
                    <span class="cur-default">
                        <span :class="`color-circle-s mr-1 ${$getTaskStatusColor(item.StatusId)}`"></span>{{ $getTaskStatusText(item.StatusId) }}
                    </span>
                </template>
            </v-data-table>
        </v-dataTable>
        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card flat >
                <v-card-title v-if="dialogItem.Name">
                    Details for : {{ dialogItem.Name }}
                </v-card-title>
                <v-card-subtitle>
                    <p v-if="dialogItem.Description">
                        {{ dialogItem.Description }}
                    </p>
                </v-card-subtitle>
                <v-card-text>
                    <v-record-preview :propSubmissionIds="dialogFormIds">
                    </v-record-preview>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false" >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import Constants from '@/util/Constants';
import StatusColor from '@/util/StatusColorMixin';
import Util from '@/util/Util';
import { mapState } from 'vuex';
import DataTable from '@/controls/DataTable.vue';
import RecordPreview from '../../controls/RecordPreview';
import { IListParameters } from '@/models/IApiModels';

export default {
    name: 'TaskList',
    mixins: [StatusColor],
    components: {
        'v-dataTable': DataTable,
        'v-record-preview': RecordPreview
    },
    data: () => ({
        dialog: false,
        dialogItem: {},
        dialogFormIds: [],
        isBusy: false,
        isDeleting: false,
        viewStatus: {},
        deletingPermission: false,
        viewStatusLabel: true,
        abilityToSearch: true,
        viewStatusOptions: Constants.TASK_STATUS_FILTER,
        searchText: '',
        searchOptions: Constants.SEARCH_OPTIONS,
        searchOnFields: ['Name', 'Description'],
        searchAt: {},
        searchAtOptions: Constants.SEARCH_AT_OPTIONS,
        sortDefault: {
            Name: 1
        },
        sortOptions: {
            sortBy: [],
            sortDesc: []
        },
        headers: [
            { text: 'Name', align: 'start', value: 'Name' },
            { text: 'Details', align: 'start', value: '_id' },
            { text: 'Last Update', value: 'UpdateDate', width: '180px' },
            { text: 'Status', value: 'StatusId', width: '150px' },
            { text: 'Status Description', value: 'StatusDescription', width: '150px' },
        ],
        tableData: [],
        pageSizes: Constants.PAGE_SIZE_5,
        pager: {
            size: 10,
            page: 1,
            pages: 1,
            total: 0
        },
        isPublishing: false,
        publishCounter: 0,
        selected: [],
        snapshot: null
    }),
    mounted () {
        this.searchAt = this.searchAtOptions[2];

        const status = this.viewStatusOptions.find(o => o.id === parseInt((this.$ls.get(`${this.$options.name}_ViewStatus`) || '0'), 10));
        this.viewStatus = status || this.viewStatusOptions[0];

        const pageSize = this.$ls.get(`${this.$options.name}_PageSize`);
        if (pageSize) this.pager.size = parseInt(pageSize, 10);

        this.loadData();
    },
    methods: {
        showDetails (item) {
            this.dialog = true;
            this.dialogItem = item;
            this.dialogFormIds = [];
            for (let index = 0; index < item.WorkflowMetaData.length; index++) {
                const element = item.WorkflowMetaData[index];
                this.dialogFormIds.push(element.AnswerId);
            }
        },
        async loadData () {
            if (!this.viewProject._id) return;
            this.isBusy = true;
            this.$showProgress();
            try {
                const listParams = new IListParameters();
                listParams.searchFields = this.searchOnFields;
                listParams.searchText = this.searchText;
                listParams.searchAt = this.searchAt;
                listParams.pager = this.pager;
                listParams.tableOptions = this.sortOptions;
                listParams.viewStatusId = this.viewStatus.id;
                listParams.where = {
                    StatusId: { $ne: 'C' },
                    AssigneeId: this.user._id
                };

                // apiRequest matches IApiRequest
                const apiRequest = Util.buildGetParams(listParams);
                // add a default sort
                if (!apiRequest.params.sort || Object.keys(apiRequest.params.sort).length === 0) {
                    apiRequest.params.sort = this.sortDefault;
                }

                const res = await this.$http.post('/task/list', apiRequest.params);
                const d = res.data;

                // Data.
                this.tableData = d.d || [];

                // Pager.
                if (d.p) this.pager = d.p;
                this.$ls.set(`${this.$options.name}_PageSize`, this.pager.size);

                // const res = await this.$http.get('/Tasks');
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
                this.$hideProgress();
            }
        },
        viewStatusClick (selectedStatus) {
            this.viewStatus = selectedStatus;
            this.$ls.set(`${this.$options.name}_ViewStatus`, this.viewStatus.text);
            this.pager.page = 1;
            this.loadData();
        },
        toggleSearchAt (searchText) {
            this.searchText = searchText;
            let pos = this.searchAtOptions.indexOf(this.searchAt);
            pos += 1;
            if (pos === this.searchAtOptions.length) pos = 0;
            this.searchAt = this.searchAtOptions[pos];
            if (this.searchText !== undefined) {
                if (this.searchText.trim().length) {
                    this.pager.page = 1;
                    this.loadData();
                }
            }
            this.$refs.childComponentRef.$refs.search.focus();
        },
        searchChange (searchText) {
            this.searchText = searchText;
            clearTimeout(this.searchTimer);
            this.searchTimer = setTimeout(() => {
                this.pager.page = 1;
                this.loadData();
            }, 300);
        },
        onSelect (item) {
            if (item.StatusId === 'C') return;

            if (this.publishCounter === 1) {
                this.publishCounter = 0;
                return;
            }
            // Lets update the task status
            this.setTaskStatus('A', item);
            // lets set the task status to Active on click
            this.$router.push({ name: 'TaskAct', query: { id: item.FormId, taskId: item._id } });
        },
        async setTaskStatus (status, item) {
            try {
                const input = {
                    _id: item._id,
                    StatusId: status.toUpperCase()
                };
                const res = await this.$http.put('/Task', input);
                if (!res.data.d) {
                    this.$info('Data Save', 'Task status could not be updated.');
                    this.taskDetails = {};
                }
                else {
                    this.taskDetails = res.data.d;
                }
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
        }
    },
    watch: {
        sortOptions: {
            handler () {
                this.loadData();
            },
            deep: true,
        },
        viewProject () {
            this.loadData();
        }
    },
    computed: {
        ...mapState({
            viewProject: 'viewProject',
            user: 'user'
        })
    }
};
</script>

<style scoped>
.forStatus::before {
    content: "Status";
    color: rgba(0,0,0,.54);
    font-size: 9pt;
    position: absolute;
    margin-top: 35px;
}
</style>
