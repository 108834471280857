export default class ImageResize {
    constructor (width, height, quality) {
        this.width = width;
        this.height = height;
        this.quality = quality || 0.9;
    }

    async resize (image) {
        return new Promise(resolve => {
            /* const blob = image.base64String
                ? new Blob([new Uint8Array(decode(image.base64String))], { type: `image/${image.format}` })
                : await fetch(image.dataUrl).then(res => res.blob()); */
            const img = new Image();
            img.onload = () => {
                // Resize img
                const canvas = document.createElement('canvas');
                let width = img.width;
                let height = img.height;

                if (this.width && this.height) {
                    if (width >= height && width > this.width) {
                        const ratio = this.width / width;
                        height = Math.floor(height * ratio);
                        width = this.width;
                    }
                    else if (height > this.height) {
                        const ratio = this.height / height;
                        width = Math.floor(width * ratio);
                        height = this.height;
                    }
                }
                else if (this.width && width > this.width) {
                    const ratio = this.width / width;
                    width = this.width;
                    height = Math.floor(height * ratio);
                }
                else if (this.height && height > this.height) {
                    const ratio = this.height / height;
                    height = this.height;
                    width = Math.floor(width * ratio);
                }

                canvas.width = width;
                canvas.height = height;
                canvas.getContext('2d').drawImage(img, 0, 0, width, height);
                resolve(canvas.toDataURL(image.format, this.quality));
                /* canvas.toBlob(function (blob) {
                    resolve((blob);
                }, image.format, this.quality); */
            };
            if (typeof image === 'string') {
                img.src = image;
            }
            else {
                img.src = image.dataUrl;
            }
        });
    }

    fileToDataUrl (file) {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.onload = readerEvent => {
                resolve(readerEvent.target.result);
            };
            reader.readAsDataURL(file);
        });
    }
}
