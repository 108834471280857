<template>
    <v-text-field
        ref="field"
        v-model="model"
        v-on:keyup.enter="onEnter"
        @input="handleChange"
        @click:append="onAppendClick"
        @paste="onPaste"
        :error-messages="errorMessages"
        prepend-icon="mdi-card-text-outline"
        v-bind="$attrs">
    </v-text-field>
</template>

<script>
export default {
    name: 'v-filter-text',
    props: {
        value: null,
        'error-messages': null,
    },
    data () {
        return {
            model: this.value,
        };
    },
    methods: {
        handleChange () {
            this.$emit('change', this.model);
        },
        focus () {
            this.$refs.field.focus();
        },
        onEnter () {
            return this.$emit('send-value', this.model);
        },
        onAppendClick () {
            return this.$emit('append-click', this.model);
        },
        onPaste (evt) {
            this.$emit('paste', evt);
        },
    },
    watch: {
        value () {
            this.model = this.value;
        },
    }
};
</script>
