<template>
    <v-container>
        <v-row>
            <v-col sm="12">
                <div class="font-weight-light text-center mt-10" style="font-size:40pt;">{{ title }}</div>
                <div class="text-center opa-5"><v-icon color="accent" style="font-size:80pt;">mdi-lock-outline</v-icon></div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'SignOut',
    mounted () {
        this.signOut();
    },
    data: () => ({
        title: 'Sign Out'
    }),
    methods: {
        async signOut () {
            try {
                const ref = this.user._ref;
                this.$ls.set('User', null);
                this.$ls.set('Token', null);
                await this.$db.cleanAllTablesExceptAnswers();
                this.$store.dispatch('notAuthed');
                this.$store.dispatch('reset');
                await this.$http.get(`/User/signout/${ref}`);
                this.$router.push({ name: 'SignIn' });
                this.$root.$emit('SRVY_Authed', false);
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
        }
    },
    computed: {
        ...mapState({
            user: 'user'
        })
    }
};
</script>
