<template>
    <div>
        <div v-if="!defaultImage">
            <img
                ref="imgRef"
                :src="image"
                style="width: 50%;"
                crossorigin="anonymous"
                @click="showMarkerArea"/>
        </div>
        <div v-if="defaultImage">
            <img
                ref="imgRef"
                src="../assets/defaultSketchImage.svg"
                style="width: 50%;"
                crossorigin="anonymous"
                @click="showMarkerArea"/>
        </div>
    </div>

  </template>

<script>
import * as markerjs2 from 'markerjs2';

export default {
    name: 'SketchImage',
    props: [
        'defaultImage',
        'image',
        'imageData'
    ],
    data: function () {
        return {
            selectedImageData: '',
        };
    },
    mounted () {},
    methods: {
        showMarkerArea () {
            const markerArea = new markerjs2.MarkerArea(this.$refs.imgRef);
            markerArea.settings.displayMode = 'popup';
            markerArea.addEventListener('render', event => {
                this.$refs.imgRef.src = event.dataUrl;
                this.selectedImageData = this.$refs.imgRef.src;
                this.emitSelectedImageData(this.selectedImageData);
            });
            markerArea.show();
        },
        emitSelectedImageData (item) {
            this.selectedImageData = item;
            this.$emit('image-data', this.selectedImageData, this.imageData);
        },
    },
};

</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
