<template>
    <div>
        <div class="d-flex">
            <div class="flex-grow-1">
                <v-text-field
                    v-model="answerValue"
                    @input="emitValue"
                    :prepend-icon="icon"
                    :readonly="readonly"
                    :prefix="prefix"
                    :suffix="suffix"
                    :placeholder="placeholder"
                    :hint="hint"
                    persistent-hint
                    clearable
                    clear-icon="mdi-close-circle"
                    @keydown.enter.prevent="emitOnNextQuestion">
                </v-text-field>
            </div>
            <div class="flex-shrink-1 pt-3 pl-2" >
                <v-btn color="primary" v-if="showScanButton" @click="startScanning">Scan</v-btn>
            </div>
        </div>
        <div class="d-flex">
            <div class="flex-grow-1 pt-3 pl-2">
                <StreamBarcodeReader
                    ref="scanner"
                    :autofocus="true"
                    :aspect-ratio="2"
                    @decode="(a, b, c) => onDecode(a, b, c)"
                    :full-width-barcode="fullWidthBarcode"
                    @loaded="() => onLoaded()">
                </StreamBarcodeReader>
            </div>
        </div>
        <div class="d-flex" v-if="scannerRunning">
            <div class="flex-grow-1 pt-3 pl-2">
                <v-btn color="warning" @click="stopScanning">Cancel</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import StreamBarcodeReader from '@/controls/StreamBarcodeReader.vue';

export default {
    name: 'QRScanner',
    props: [
        'questionValue',
        'icon',
        'readonly',
        'prefix',
        'suffix',
        'placeholder',
        'hint',
        'scanvin',
        'scanreg',
        'scanengine'
    ],
    components: {
        StreamBarcodeReader
    },
    data: function () {
        return {
            answerValue: this.questionValue,
            id: null,
            showScanButton: true,
            scannerRunning: false,
            fullWidthBarcode: false,
        };
    },
    mounted () {
        // console.log(this.scanvin);
        // console.log(this.scanreg);
        // console.log(this.scanengine);
    },
    methods: {
        onDecode (a, b, c) {
            this.answerValue = a;
        },
        onLoaded () {
        },
        startScanning () {
            if (this.scanvin || this.scanengine || this.scanreg) {
                this.fullWidthBarcode = true;
            }
            else this.fullWidthBarcode = false;

            if (typeof this.answerValue === 'string') {
                this.answerValue = '';
            };
            this.$refs.scanner.startQRScanner();
            this.showScanButton = false;
            this.scannerRunning = true;
        },
        stopScanning () {
            this.$refs.scanner.stopQRScanner();
            this.showScanButton = true;
            this.scannerRunning = false;
            this.showScanner = false;
        },
        emitOnNextQuestion () {
            this.$emit('next-question', this.onNextQuestion);
        },
        emitValue () {
            this.$emit('update-value', this.answerValue);
        }
    },
    watch: {
        answerValue: {
            handler: function (newValue) {
                if (newValue !== '') {
                    // Lets try and decode the string value to a valid VIN
                    if (this.scanreg || this.scanengine || this.scanvin) {
                        try {
                            const vinValues = newValue.split('%');
                            console.log(vinValues);
                            if (vinValues.length <= 16 && vinValues.length > 1) {
                                if (this.scanreg) newValue = vinValues[6];
                                if (this.scanvin) newValue = vinValues[12];
                                if (this.scanengine) newValue = vinValues[13];
                                this.$refs.scanner.stopQRScanner();
                                this.showScanButton = true;
                                this.scannerRunning = false;
                                this.answerValue = newValue;
                                this.$emit('update-value', newValue);
                            }
                        }
                        catch (error) {
                            console.log(error);
                        }
                    }
                    else {
                        this.answerValue = newValue;
                        this.$refs.scanner.stopQRScanner();
                        this.showScanButton = true;
                        this.scannerRunning = false;
                        this.answerValue = newValue;
                        this.$emit('update-value', newValue);
                    }
                };
            },
        },
    },
};
</script>
