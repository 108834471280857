<template>
    <div>
        <div v-if="!disableFilter">
            <v-text-field v-model="filterValue"
                          class="w-512-max"
                          dense
                          single-line
                          hint="Type to search and filter the available options"
                          persistent-hint
                          clearable
                          @input="filterData"
                          @click:clear="clearFilter">
            </v-text-field>
            <div v-if="totalPages > 1" class="text-center">
                <v-pagination v-model="currentPage"
                              :length="totalPages"
                              :total-visible="5">
                </v-pagination>
            </div>
        </div>
        <div style="max-height: 350px; overflow-x: auto;">
            <div>
                <v-radio-group v-model="selectedUserId" @change="onRadioValueChanged">
                    <v-radio v-for="user in filteredUsers"
                             :key="user.UserId"
                             :label="user.UserFullName"
                             :value="user.UserId">
                    </v-radio>
                </v-radio-group>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UserLookup',
    props: {
        disableFilter: {
            type: Boolean,
            default: null,
        },
        items: {
            type: Array,
            default () {
                return [];
            }
        }
    },
    data () {
        return {
            filterValue: '',
            itemsCnt: 0,
            filteredUsers: [],
            selectedUserId: null,
            currentPage: 1,
            itemsPerPage: 20,
            totalPages: 1,
            startIndex: 0,
            stopIndex: 20,
        };
    },
    mounted () {
    },
    methods: {
        setRoleUsers () {
            if (!this.items || this.items.length === 0) return;

            this.itemsCnt = this.items.length;
            this.setTotalPages(this.itemsCnt);
            this.filterData();
        },
        onRadioValueChanged () {
            // Emit an event when the selected user changes
            if (!this.selectedUserId) return;

            // Should always return a single array item
            const selectedUser = this.filteredUsers.filter(x => x.UserId === this.selectedUserId);
            if (selectedUser) {
                this.$emit('selected-users', selectedUser);
            }
        },
        setStartStopIndexes (currentpage = null) {
            const cp = currentpage || this.currentPage;
            this.startIndex = ((cp - 1) * this.itemsPerPage);
            this.stopIndex = this.startIndex + this.itemsPerPage;
            if (this.stopIndex > this.itemsCnt) this.stopIndex = this.itemsCnt;
        },
        setTotalPages (userCnt) {
            this.totalPages = Math.ceil(userCnt / this.itemsPerPage);
        },
        filterData () {
            this.setStartStopIndexes();
            if (!this.filterValue && this.filterValue === '') {
                this.filteredUsers = this.items.slice(this.startIndex, this.stopIndex);
            }
            else {
                try {
                    // Escape special characters in filterValue to safely use it in a regex
                    let escapedFilterValue = this.filterValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                    // Create a regex pattern with word boundaries for the filter value
                    let regex = new RegExp('\\b' + escapedFilterValue + '\\S' + '\\b', 'i');

                    let filteredItems = this.items
                        .filter(element => regex.test(element.UserFullName))
                        .slice(this.startIndex, this.stopIndex);

                    if (filteredItems.length === 0) {
                        escapedFilterValue = this.filterValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                        regex = new RegExp(escapedFilterValue, 'i');

                        filteredItems = this.items
                            .filter(element => regex.test(element.UserFullName))
                            .slice(this.startIndex, this.stopIndex);
                    }

                    this.filteredUsers = filteredItems;
                }
                catch (error) {
                    this.filteredUsers = [];
                }
            }

            this.setSelectedUser();
        },
        clearFilter () {
            this.filterValue = '';
            this.filterData();
        },
        setSelectedUser () {
            if (!this.selectedUserId) return;

            const selectedUserIndex = this.filteredUsers.findIndex(x => x.UserId.toString() === this.selectedUserId.toString());
            if (selectedUserIndex > -1) {
                this.selectedUserId = this.filteredUsers[selectedUserIndex].UserId;
            }
            else {
                this.selectedUserId = null;
            }
        }
    },
    watch: {
        items: 'setRoleUsers',
        currentPage: {
            handler (newValues) {
                this.filterValue = '';
                this.filterData();
            }
        },
    },
};
</script>
