<template>
    <v-container>
        <v-row>
            <v-file-input ref="upload"
                          v-model="localData"
                          :rules="rules"
                          :accept="allowedUploadType"
                          :placeholder="placeholder"
                          clearable
                          show-size
                          truncate-length="200"
                          class="ma-0 pa-0"
                          @change="onChange"
                          @update:error="$emit('update:validState', $event)">
            </v-file-input>
        </v-row>
        <v-row v-if="isImageUploadType" class="d-flex justify-center">
            <v-col>
                <v-img v-if="imageData === null" :src="noImageData.src" contain :height="noImageHeight"></v-img>
                <v-img v-if="imageData !== null" :src="imageData.src" :key="imageData.src" :error="noImage" contain :max-height="imageHeight">
            </v-img>
            </v-col>
        </v-row>
        <v-row v-if="!isImageUploadType && hasFilename" class="d-flex justify-center">
            <v-btn @click="formDocumentClick">
                View
            </v-btn>
        </v-row>
    </v-container>
</template>
<script>

import ImageTools from '@/util/ImageTools';
import Constants from '@/util/Constants';

export default {
    name: 'v-FileUpload',
    props: ['parentData', 'parentImageData', 'uploadType', 'uploadSizeLimit', 'validState', 'placeholder', 'isOptional'],
    async created () {
        const promise = this.imageTools.loadImageData(Constants.NO_IMAGE)
            .then(img => {
                this.noImageData = img;
            });

        await promise;

        if (this.isImageUploadType) {
            await this.loadImageData();
        }

        this.$refs.upload.focus();
        this.$refs.upload.blur();

        this.$emit('update:validState', !this.$refs.upload.validate());
    },
    data () {
        return {
            localData: this.parentData,
            imageData: this.parentImageData,
            noImageData: new Image(),
            imageTools: new ImageTools(),
            rules: [
                value => !value || this.configuredSizeLimit === 0 || value.size < this.configuredSizeLimit || `Upload file size must be less than ${this.uploadSizeLimit} MB`,
                value => this.isOptional || (value !== undefined && value !== null) || 'Input is required',
            ]
        };
    },
    methods: {
        async onChange () {
            if (this.localData === undefined) {
                const me = this;
                setTimeout(function () {
                    me.localData = me.parentData;
                }, 1);
            }
            else {
                this.$emit('update:parentData', this.localData);
                await this.loadImageData();
            }
        },
        async formDocumentClick () {
            const objectUrl = URL.createObjectURL(this.localData);
            window.open(objectUrl);
            URL.revokeObjectURL(objectUrl);
        },
        async loadImageData () {
            if (this.isImageUploadType && this.localData) {
                const promise = this.imageTools.fileToImage(this.localData)
                    .then(data => {
                        this.imageData = data;
                        this.$emit('update:parentImageData', this.imageData);
                    })
                    .catch(() => {
                    });

                await promise;
            }
            else {
                this.imageData = null;
                this.$emit('update:parentImageData', this.imageData);
            }
        },
        computeHeight () {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 200;
                case 'sm': return 400;
                case 'md': return 600;
                case 'lg': return 800;
                case 'xl': return 1000;
                default: return 200;
            };
        },
        documentUploadTypes () {
            return [
                'text/csv',
                'application/pdf',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/rtf'
            ];
        }
    },
    computed: {
        configuredSizeLimit () {
            return this.uploadSizeLimit * 1024 * 1024;
        },
        noImage () {
            return Constants.NO_IMAGE;
        },
        hasFilename () {
            return this?.localData?.name !== undefined;
        },
        isImageUploadType () {
            return this.uploadType === Constants.UPLOAD_FILE_TYPE.Images;
        },
        allowedUploadType () {
            return this.uploadType === Constants.UPLOAD_FILE_TYPE.Images ? 'image/*' : this.documentUploadTypes().join(',');
        },
        imageHeight () {
            const computeHeight = this.computeHeight();
            if (this.imageData === null) return 0;
            return computeHeight > this.imageData.height ? this.imageData.height : computeHeight;
        },
        noImageHeight () {
            const computeHeight = this.computeHeight();
            if (this.noImageData === null) return 0;
            return computeHeight > this.noImageData.height ? this.noImageData.height : computeHeight;
        }
    },
    watch: {
        uploadType () {
            this.imageData = null;
            this.localData = null;
        },
        uploadSizeLimit () {
            this.$refs.upload.validate();
        },
        isOptional () {
            this.$refs.upload.validate();
        }
    }
};
</script>

<style lang="scss" scoped></style>
