<template>
    <div>
        <div class="d-flex">
            <div class="flex-shrink-1 pt-3 pl-2" >
                <v-btn color="primary" v-if="showScanButton" @click="startScanning">Scan</v-btn>
                <v-icon class="mb-1 opa-5 ml-2" medium>{{ icon }}</v-icon>
            </div>
        </div>
        <div v-if="displayQRValue" class="d-flex">
            <div class="flex-shrink-1 pt-3 pl-2" >
                <v-data-table
                    :headers="headers"
                    :items="tableData"
                    :loading="isBusy"
                    no-data-text="No data."
                    hide-default-footer
                    class="elevation-1">
                    <template v-slot:item.DriverId="{ item }">
                        <div class="subtitle-2 cur-default">{{ item.DriverId }}</div>
                    </template>
                    <template v-slot:item.DriverName="{ item }">
                        <div class="subtitle-2 cur-default">{{ item.DriverName }}</div>
                    </template>
                    <template v-slot:item.DriverSurname="{ item }">
                        <div class="subtitle-2 cur-default">{{ item.DriverSurname }}</div>
                    </template>
                </v-data-table>
            </div>
        </div>
        <div class="d-flex">
            <div class="flex-grow-1 pt-3 pl-2">
                <StreamBarcodeReader
                    ref="scanner"
                    :autofocus="true"
                    :aspect-ratio="2"
                    @decode="(a, b, c) => onDecode(a, b, c)"
                    @loaded="() => onLoaded()">
                </StreamBarcodeReader>
            </div>
        </div>
        <div class="d-flex"  v-if="scannerRunning">
            <div class="flex-grow-1 pt-3 pl-2">
                <v-btn color="warning" @click="stopScanning">Cancel</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import StreamBarcodeReader from '@/controls/StreamBarcodeReader.vue';

export default {
    name: 'QRScanner',
    props: [
        'questionValue',
        'icon',
    ],
    components: {
        StreamBarcodeReader
    },
    data: function () {
        return {
            answerValue: this.questionValue,
            id: null,
            showScanButton: true,
            scannerRunning: false,
            tableData: [],
            displayQRValue: false,
            headers: [
                { text: 'Driver Id', value: 'DriverId' },
                { text: 'Driver Name', value: 'DriverName' },
                { text: 'Driver Surname', value: 'DriverSurname' },
            ],
            isBusy: false,
        };
    },
    mounted () {},
    methods: {
        onDecode (a, b, c) {
            this.answerValue = a;
        },
        onLoaded () {},
        startScanning () {
            if (typeof this.tableData === 'object') {
                this.answerValue = '';
                this.tableData = [];
                this.displayQRValue = false;
            };
            this.$refs.scanner.startQRScanner();
            this.showScanButton = false;
            this.scannerRunning = true;
        },
        stopScanning () {
            this.$refs.scanner.stopQRScanner();
            this.showScanButton = true;
            this.scannerRunning = false;
        },
        emitOnNextQuestion () {
            this.$emit('next-question', this.onNextQuestion);
        },
        emitValue () {
            this.$emit('update-value', this.answerValue);
        },
        createTableData (answer) {
            const answerArray = answer !== '' ? JSON.parse(answer) : null;
            if (typeof answerArray === 'object' && answerArray !== null) {
                this.tableData = [
                    {
                        DriverId: answerArray.DriverId,
                        DriverName: answerArray.DriverName,
                        DriverSurname: answerArray.DriverSurname
                    }
                ];
                this.displayQRValue = true;
            }
            this.$emit('update-value', this.tableData);
        }
    },
    watch: {
        answerValue: {
            handler: function (newValue) {
                if (newValue !== '') {
                    this.$refs.scanner.stopQRScanner();
                    this.showScanButton = true;
                    this.scannerRunning = false;
                    this.answerValue = newValue;
                    this.createTableData(this.answerValue);
                };
            },
        },
    },
};
</script>
