<template>
    <v-select
        ref="field"
        v-model="model"
        :items="options"
        v-on:keyup.enter="onEnter"
        @change="handleChange"
        @click:append="onAppendClick"
        :error-messages="errorMessages"
        :multiple="multiple"
        prepend-icon="mdi-list-box-outline"
        item-value="Value"
        item-text="Value"
        v-bind="$attrs">
        <template v-if="multiple" v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0" small>
                <span>{{ item.Value }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption">
                (... +{{ model.length - 1 }} more)
            </span>
        </template>
    </v-select>
</template>

<script>
export default {
    name: 'v-filter-select',
    props: {
        value: null,
        'error-messages': null,
        list: Array,
        many: Boolean
    },
    data () {
        return {
            model: this.value,
            options: this.list,
            multiple: this.many,
        };
    },
    methods: {
        handleChange () {
            this.$emit('change', this.model);
        },
        focus () {
            this.$refs.field.focus();
        },
        onEnter () {
            return this.$emit('send-value', this.model);
        },
        onAppendClick () {
            return this.$emit('append-click', this.model);
        }
    },
    watch: {
        value () {
            this.model = this.value;
        },
    }
};
</script>
