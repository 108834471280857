import { render, staticRenderFns } from "./SketchImage.vue?vue&type=template&id=c4fdabde&scoped=true"
import script from "./SketchImage.vue?vue&type=script&lang=js"
export * from "./SketchImage.vue?vue&type=script&lang=js"
import style0 from "./SketchImage.vue?vue&type=style&index=0&id=c4fdabde&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4fdabde",
  null
  
)

export default component.exports