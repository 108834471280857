<template>
    <v-container>
        <v-row>
            <v-col sm="12">
                <h1 class="title font-weight-light">
                    <v-icon @click="onCancelAsync">mdi-arrow-left-circle</v-icon>
                    Task : <span class="subtitle-1 ml-2 primary--text">{{taskDetails.Name}}</span>
                </h1>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col sm="12">
                <v-card :loading="isBusy">
                    <v-container>
                        <v-tabs v-model="tab">
                            <v-tab>Task Details</v-tab>
                            <v-tab>Task Form</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <v-card flat>
                                <v-card-subtitle>
                                    <p v-if="taskDetails.Description">
                                        {{ taskDetails.Description }}
                                    </p>
                                </v-card-subtitle>
                                <v-card-text>
                                    <v-record-preview :propSubmissionIds="submissionIds">
                                    </v-record-preview>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-act v-if="formId !== null" :propId="formId"  :propRouteOnSave="true" :propTaskForm='true' @on-route="onRoute" @on-save="onSave"></v-act>
                        </v-tab-item>
                        </v-tabs-items>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Act from '../Survey/Act';
import RecordPreview from '../../controls/RecordPreview';
import { mapState } from 'vuex';

export default {
    name: 'TaskAct',
    components: {
        'v-act': Act,
        'v-record-preview': RecordPreview
    },
    mounted () {
        if (this.$route.query.id) {
            this.formId = this.$route.query.id;
        }
        if (this.$route.query.taskId) {
            this.taskId = this.$route.query.taskId;
            this.getTaskDetails(this.taskId);
        }
    },
    data: () => ({
        isBusy: false,
        formId: null,
        tab: 0,
        submissionIds: [],
        taskDetails: {}
    }),
    methods: {
        async getTaskDetails (taskId) {
            this.taskDetails = {};
            if (!this.viewProject._id) return;
            this.isBusy = true;
            this.$showProgress();
            this.searchText = '';
            try {
                const res = await this.$http.get(`/Task/${taskId}`);
                if (!res.data.d) {
                    this.$info('Data Load', 'There is no tasks available.');
                    this.taskDetails = {};
                }
                else {
                    this.taskDetails = res.data.d;
                    this.dialogFormIds = [];
                    this.submissionIds = [];
                    for (let index = 0; index < this.taskDetails.WorkflowMetaData.length; index++) {
                        const element = this.taskDetails.WorkflowMetaData[index];
                        this.submissionIds.push(element.AnswerId);
                    }
                    if (this.submissionIds.length === 0) this.tab = 1;
                }
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
                this.$hideProgress();
            }
        },
        onSave (data, fileUploads) {
        },
        async onRoute () {
            // lets just set the task status to complete before we route
            await this.setTaskStatus('C', this.taskDetails);
            const route = '/TaskList';
            this.$router.push({ path: route }).catch(err => {
                // Check if error is for nav to same location, do nothing, otherwise throw the error.
                if (err.name !== 'NavigationDuplicated') throw err;
            });
        },
        async setTaskStatus (status, item) {
            try {
                const input = {
                    _id: item._id,
                    StatusId: status.toUpperCase()
                };
                const res = await this.$http.put('/Task', input);
                if (!res.data.d) {
                    this.$info('Data Save', 'Task status could not be updated.');
                    this.taskDetails = {};
                }
                else {
                    this.taskDetails = res.data.d;
                }
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
        },
        async onCancelAsync () {
            this.$router.go(-1);
        },
    },
    watch: {
        viewProject () {
            // we do this to ensure we always have the right task loaded if the viewproject loaded
            if (this.taskId) this.getTaskDetails(this.taskId);
        }
    },
    computed: {
        ...mapState({
            viewProject: 'viewProject',
            user: 'user'
        })
    }
};
</script>
