import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: '#555555',
                secondary: '#333333',
                accent: '#999999', // #DE2329 #FF3A30
                error: '#F56C6C',
                info: '#409EFF',
                success: '#67C23A',
                warning: '#E6A23C'
            },
            dark: {
                primary: '#555555',
                secondary: '#333333',
                accent: '#999999', // #DE2329 #FF3A30
                error: '#F56C6C',
                info: '#409EFF',
                success: '#67C23A',
                warning: '#E6A23C'
            }
        }
    }
});
